<template>
  <b-card>

    <!-- search input -->
    <b-row class="mb-1">
      <b-col lg="4">
        <b-form-input
            v-on:keyup="onSearch"
            v-model.lazy="searchTerm"
            placeholder="Search..."
            type="text"
            class="d-inline-block mr-sm-1 mb-1 mb-sm-0"
        />
      </b-col>
      <b-col lg="8" class="d-flex justify-content-end align-items-center">
        <template v-if="$permissionAbility(DESIGNATION_CREATE, permissions)">
          <b-button
              class="flex-shrink-0"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-on:click="showModal"
          >
            Create Designation
          </b-button>
        </template>
      </b-col>
    </b-row>

    <!-- table -->
    <vue-good-table
      mode="remote"
      @on-per-page-change="onPerPageChange"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter"
      :totalRows="totalRecords"
      :isLoading.sync="isLoading"
      :rows="rows"
      :sort-options="{
        enabled: false,
        multipleColumns: true,
        initialSortBy: [
          { field: 'id', type: 'desc' },
          { field: 'name', type: 'desc' },
          { field: 'created_at', type: 'desc' },
        ],
      }"
      :columns="columns"
      :pagination-options="{
        enabled: true,
        perPage: pageLength,
      }"
      styleClass="vgt-table striped"

    >
      <template slot="table-row" slot-scope="props">
        <!-- Column: employee type -->
        <span v-if="props.column.field === 'employee_type'">
          <span class="text-nowrap">{{
            props.row?.employeeType?.data?.name
          }}</span>
        </span>

        <span v-if="props.column.field === 'format_users_count'">
          <b-badge class="ml-3" variant="light-primary">
            {{ props?.row?.users_count }}
          </b-badge>
        </span>

        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">

            <template v-if="$permissionAbility(DESIGNATION_EDIT, permissions)">
              <span @click="onShow(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="Edit2Icon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Edit"
                    size="16"
                />
              </span>
            </template>

            <template
                v-if="$permissionAbility(DESIGNATION_DELETE, permissions)"
            >
              <span @click="onDelete(props.row)">
                <feather-icon
                    v-b-tooltip.hover
                    icon="TrashIcon"
                    class="mr-50 custom-icon cursor-pointer"
                    title="Delete"
                    size="16"
                />
              </span>
            </template>

          </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template slot="pagination-bottom" slot-scope="props">
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap"> Showing 1 to </span>
            <b-form-select
              v-model="pageLength"
              :options="['10', '25', '50', '100', '500']"
              class="mx-1"
              @input="
                (value) => props.perPageChanged({ currentPerPage: value })
              "
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
              :value="1"
              :total-rows="props.total"
              :per-page="pageLength"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
              @input="(value) => props.pageChanged({ currentPage: value })"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>

    <b-modal
      id="modal-designation-form"
      centered
      :title="modelType == 'editModel' ? 'Edit' : 'Create'"
      hide-footer
      @hidden="hiddenModal"
      no-close-on-backdrop
    >
      <validation-observer ref="designationValidation">
        <b-form v-on:submit.prevent="validationForm">
          <!-- name -->
          <b-form-group label="Name" label-for="name" class="required-label">
            <validation-provider #default="{ errors }" name="name" vid="name">
              <b-form-input
                id="name"
                type="text"
                v-model="name"
                :state="errors.length > 0 ? false : null"
                placeholder="Designation"
                rules="required|max:255"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- loading button -->
          <template v-if="isDesignationFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
              type="submit"
              class="float-right"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>
  </b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner, VBTooltip, BRow, BCol,
} from 'bootstrap-vue'
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, max } from "@validations";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";
import {
  DESIGNATION_CREATE,
  DESIGNATION_EDIT,
  DESIGNATION_DELETE,
} from "@/helpers/permissionsConstant";

export default {
  name: "DesignationView",
  components: {
    BCol,
    BRow,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      DESIGNATION_CREATE,
      DESIGNATION_EDIT,
      DESIGNATION_DELETE,
      modelType: "",
      designationId: "",
      name: "",
      pageLength: 10,
      columns: [
        {
          label: "Name",
          field: "name",
        },
        {
          label: "No. of Employee",
          field: "format_users_count",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      delayTimer: null,
      isLoading: false,
      isDesignationFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          { field: "id", type: "desc" },
          { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 10,
      },
    };
  },

  computed: {
    ...mapGetters({
      permissions: "userModule/getPermissions",
    }),
  },

  // async created() {
  //   try {
  //     const [employeeTypes] = await Promise.all([this.getEmployeeTypes()]);

  //     // employee types
  //     this.employeeTypesOption = (employeeTypes?.data?.data || []).map(
  //       (item) => {
  //         return {
  //           name: item.name,
  //           id: item.id,
  //         };
  //       }
  //     );
  //   } catch (error) {
  //     this.$toast({
  //       component: ToastificationContent,
  //       props: {
  //         title: "Error",
  //         icon: "BellIcon",
  //         variant: "danger",
  //         text: error?.response?.data?.message,
  //       },
  //     });
  //   }
  // },

  methods: {
    showModal() {
      this.$bvModal.show("modal-designation-form");
    },
    hiddenModal() {
      this.$bvModal.hide("modal-designation-form");
      this.resetModal();
    },
    resetModal() {
      this.modelType = "";
      this.name = "";
      this.designationId = "";
    },
    async onShow(value) {
      this.modelType = "editModel";
      this.name = value?.name;
      this.designationId = value?.id;

      this.showModal();
    },
    async onDelete(row) {
      const { id, name } = row
      this.$swal({
        title: "Warning!",
        text: `Are You Sure, You Want To Delete ${name}?`,
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Delete",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/designations/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Designation Successfully Deleted",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        this.loadItems();
      }, 1000);
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.updateParams({
        sort: params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },

    async getDesignation(params) {
      return await this.$api.get("api/designations?include=employeeType", {
        params: {
          show: params.show,
          page: params.page,
          sort: params.sort,
          q: params.q,
        },
      });
    },
    // async getEmployeeTypes() {
    //   return await this.$api.get("api/employee-types/all");
    // },

    async loadItems() {
      try {
        const [designations] = await Promise.all([
          this.getDesignation({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
          }),
        ]);

        const data = designations?.data?.data;
        const meta = designations?.data?.meta;

        this.totalRecords = meta?.pagination?.total;
        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    validationForm: async function () {
      this.$refs.designationValidation.validate().then(async (success) => {
        if (success) {
          try {
            if (this.modelType == "editModel") {
              this.isDesignationFormSubmitLoading = true;
              await this.$api.put(`/api/designations/${this.designationId}`, {
                name: this.name,
              });
              this.isDesignationFormSubmitLoading = false;
              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Designation Successfully Updated",
                },
              });
            } else {
              this.isDesignationFormSubmitLoading = true;
              await this.$api.post(
                "/api/designations",

                {
                  name: this.name,
                }
              );
              this.isDesignationFormSubmitLoading = false;
              this.hiddenModal();

              this.loadItems();

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Designation Successfully Created",
                },
              });
            }
          } catch (error) {
            this.isDesignationFormSubmitLoading = false;
            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.designationValidation.setErrors(
                error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
.actionBtn span{
  padding: 8px;
  margin: 1px;
  cursor: pointer;
}
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
